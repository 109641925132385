import * as yup from 'yup';
import { isValidWsUrl, getWSUrlWithoutPrefix } from '@models/Auth';
import { keyMap, t } from '@localization';
import { yupCommonString } from '@validationSchemas/yupValidations';

export const loginSchema = yup.object().shape({
    wsUrl: yup
        .string()
        .required()
        .label(t(keyMap.form.auth.apiSource))
        .test('isValid', t(keyMap.form.auth.apiSourceInvalidAddress), (value) => {
            if (!value || !isValidWsUrl(value)) return false;
            const parts = getWSUrlWithoutPrefix(value).split(':');
            return parts[0].length > 0;
        })
        .test('isValid', t(keyMap.form.auth.apiSourceRequiredPort), (value) => {
            if (!value) return false;
            const parts = getWSUrlWithoutPrefix(value).split(':');
            return !(parts.length === 1 || !parts[1]);
        })
        .test('isValid', t(keyMap.form.auth.apiSourceInvalidPort), (value) => {
            if (!value) return false;
            const parts = getWSUrlWithoutPrefix(value).split(':');
            return parts.length === 2 && parts[1].length === 4 && `${+parts[1]}` === parts[1];
        }),
    login: yup.string().label(t(keyMap.form.auth.login)).nullable().required(),
    password: yupCommonString.label(t(keyMap.form.auth.password)).nullable().required(),
});

export const defaultLoginRecord = yup.object().cast({
    wsUrl: '',
    login: null,
    password: null,
});
