
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {OrderSide} from './OrderSide';
import {FieldModelOrderSide} from './OrderSide';
import {OrderType} from './OrderType';
import {FieldModelOrderType} from './OrderType';
import {OrderTimeInForce} from './OrderTimeInForce';
import {FieldModelOrderTimeInForce} from './OrderTimeInForce';
import {OrderStatus} from './OrderStatus';
import {FieldModelOrderStatus} from './OrderStatus';
import {OrderRequestSourceType} from './OrderRequestSourceType';
import {FieldModelOrderRequestSourceType} from './OrderRequestSourceType';
import {SocialTradingOrderType} from './SocialTradingOrderType';
import {FieldModelSocialTradingOrderType} from './SocialTradingOrderType';
import {SocialTradingOrderInvestor} from './SocialTradingOrderInvestor';
import {FieldModelSocialTradingOrderInvestor} from './SocialTradingOrderInvestor';
import {SocialTradingOrderMaster} from './SocialTradingOrderMaster';
import {FieldModelSocialTradingOrderMaster} from './SocialTradingOrderMaster';

/**
 * Order struct
 */
class Order {
  /**
   * Initialize struct
   * @param {!number=} login
   * @param {!number=} orderId
   * @param {number=} parentOrderId
   * @param {string=} clientOrderId
   * @param {!number=} actualVersion
   * @param {!UUID=} symbolId
   * @param {!OrderSide=} orderSide
   * @param {!OrderType=} orderType
   * @param {!OrderType=} orderInitialType
   * @param {!OrderTimeInForce=} orderTimeInForce
   * @param {!OrderStatus=} orderStatus
   * @param {number=} price
   * @param {number=} stopPrice
   * @param {number=} takeProfit
   * @param {number=} stopLoss
   * @param {!number=} amount
   * @param {!number=} executedAmount
   * @param {!number=} remainingAmount
   * @param {Date=} expiration
   * @param {!string=} accountComment
   * @param {!string=} managerComment
   * @param {!number=} magic
   * @param {!Date=} created
   * @param {!Date=} modified
   * @param {!Date=} archived
   * @param {!Date=} deleted
   * @param {number=} openPrice
   * @param {number=} closePrice
   * @param {number=} closeAmount
   * @param {number=} openMarginRate
   * @param {number=} commission
   * @param {number=} swap
   * @param {number=} openMarkup
   * @param {UUID=} gatewayId
   * @param {number=} closeProfit
   * @param {!Date=} opened
   * @param {!Date=} closed
   * @param {number=} openAccountMargin
   * @param {number=} openAccountEquity
   * @param {number=} openAccountCredit
   * @param {number=} openAmount
   * @param {!OrderRequestSourceType=} orderRequestSourceType
   * @param {boolean=} isCreatedViaQuickTrading
   * @param {boolean=} isCreatedViaFastTrader
   * @param {!SocialTradingOrderType=} socialTradingOrderType
   * @param {SocialTradingOrderInvestor=} socialTradingOrderInvestor
   * @param {SocialTradingOrderMaster=} socialTradingOrderMaster
   * @constructor
   */
  constructor (login = new UInt64(0, 0), orderId = new UInt64(0, 0), parentOrderId = null, clientOrderId = null, actualVersion = new UInt64(0, 0), symbolId = new UUID(), orderSide = new OrderSide(), orderType = new OrderType(), orderInitialType = new OrderType(), orderTimeInForce = new OrderTimeInForce(), orderStatus = new OrderStatus(), price = null, stopPrice = null, takeProfit = null, stopLoss = null, amount = 0.0, executedAmount = 0.0, remainingAmount = 0.0, expiration = null, accountComment = '', managerComment = '', magic = 0, created = new Date(0), modified = new Date(0), archived = new Date(0), deleted = new Date(0), openPrice = null, closePrice = null, closeAmount = null, openMarginRate = null, commission = null, swap = null, openMarkup = null, gatewayId = null, closeProfit = null, opened = new Date(0), closed = new Date(0), openAccountMargin = null, openAccountEquity = null, openAccountCredit = null, openAmount = null, orderRequestSourceType = new OrderRequestSourceType(), isCreatedViaQuickTrading = null, isCreatedViaFastTrader = null, socialTradingOrderType = new SocialTradingOrderType(), socialTradingOrderInvestor = null, socialTradingOrderMaster = null) {
    this.Login = login
    this.OrderId = orderId
    this.ParentOrderId = parentOrderId
    this.ClientOrderId = clientOrderId
    this.ActualVersion = actualVersion
    this.SymbolId = symbolId
    this.OrderSide = orderSide
    this.OrderType = orderType
    this.OrderInitialType = orderInitialType
    this.OrderTimeInForce = orderTimeInForce
    this.OrderStatus = orderStatus
    this.Price = price
    this.StopPrice = stopPrice
    this.TakeProfit = takeProfit
    this.StopLoss = stopLoss
    this.Amount = amount
    this.ExecutedAmount = executedAmount
    this.RemainingAmount = remainingAmount
    this.Expiration = expiration
    this.AccountComment = accountComment
    this.ManagerComment = managerComment
    this.Magic = magic
    this.Created = created
    this.Modified = modified
    this.Archived = archived
    this.Deleted = deleted
    this.OpenPrice = openPrice
    this.ClosePrice = closePrice
    this.CloseAmount = closeAmount
    this.OpenMarginRate = openMarginRate
    this.Commission = commission
    this.Swap = swap
    this.OpenMarkup = openMarkup
    this.GatewayId = gatewayId
    this.CloseProfit = closeProfit
    this.Opened = opened
    this.Closed = closed
    this.OpenAccountMargin = openAccountMargin
    this.OpenAccountEquity = openAccountEquity
    this.OpenAccountCredit = openAccountCredit
    this.OpenAmount = openAmount
    this.OrderRequestSourceType = orderRequestSourceType
    this.IsCreatedViaQuickTrading = isCreatedViaQuickTrading
    this.IsCreatedViaFastTrader = isCreatedViaFastTrader
    this.SocialTradingOrderType = socialTradingOrderType
    this.SocialTradingOrderInvestor = socialTradingOrderInvestor
    this.SocialTradingOrderMaster = socialTradingOrderMaster
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Order}
   * @param {!Order} other Other struct
   * @returns {!Order} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.OrderId != null) {
      this.OrderId = UInt64.fromNumber(other.OrderId)
    } else {
      this.OrderId = null
    }
    if (other.ParentOrderId != null) {
      this.ParentOrderId = UInt64.fromNumber(other.ParentOrderId)
    } else {
      this.ParentOrderId = null
    }
    if (other.ClientOrderId != null) {
      this.ClientOrderId = other.ClientOrderId
    } else {
      this.ClientOrderId = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.OrderSide != null) {
      this.OrderSide = OrderSide.fromObject(other.OrderSide)
    } else {
      this.OrderSide = null
    }
    if (other.OrderType != null) {
      this.OrderType = OrderType.fromObject(other.OrderType)
    } else {
      this.OrderType = null
    }
    if (other.OrderInitialType != null) {
      this.OrderInitialType = OrderType.fromObject(other.OrderInitialType)
    } else {
      this.OrderInitialType = null
    }
    if (other.OrderTimeInForce != null) {
      this.OrderTimeInForce = OrderTimeInForce.fromObject(other.OrderTimeInForce)
    } else {
      this.OrderTimeInForce = null
    }
    if (other.OrderStatus != null) {
      this.OrderStatus = OrderStatus.fromObject(other.OrderStatus)
    } else {
      this.OrderStatus = null
    }
    if (other.Price != null) {
      this.Price = other.Price
    } else {
      this.Price = null
    }
    if (other.StopPrice != null) {
      this.StopPrice = other.StopPrice
    } else {
      this.StopPrice = null
    }
    if (other.TakeProfit != null) {
      this.TakeProfit = other.TakeProfit
    } else {
      this.TakeProfit = null
    }
    if (other.StopLoss != null) {
      this.StopLoss = other.StopLoss
    } else {
      this.StopLoss = null
    }
    if (other.Amount != null) {
      this.Amount = other.Amount
    } else {
      this.Amount = null
    }
    if (other.ExecutedAmount != null) {
      this.ExecutedAmount = other.ExecutedAmount
    } else {
      this.ExecutedAmount = null
    }
    if (other.RemainingAmount != null) {
      this.RemainingAmount = other.RemainingAmount
    } else {
      this.RemainingAmount = null
    }
    if (other.Expiration != null) {
      if (other.Expiration instanceof Date) {
        this.Expiration = new Date(other.Expiration.getTime())
      } else {
        this.Expiration = new Date(Math.round(other.Expiration / 1000000))
      }
    } else {
      this.Expiration = null
    }
    if (other.AccountComment != null) {
      this.AccountComment = other.AccountComment
    } else {
      this.AccountComment = null
    }
    if (other.ManagerComment != null) {
      this.ManagerComment = other.ManagerComment
    } else {
      this.ManagerComment = null
    }
    if (other.Magic != null) {
      this.Magic = other.Magic
    } else {
      this.Magic = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      if (other.Modified instanceof Date) {
        this.Modified = new Date(other.Modified.getTime())
      } else {
        this.Modified = new Date(Math.round(other.Modified / 1000000))
      }
    } else {
      this.Modified = null
    }
    if (other.Archived != null) {
      if (other.Archived instanceof Date) {
        this.Archived = new Date(other.Archived.getTime())
      } else {
        this.Archived = new Date(Math.round(other.Archived / 1000000))
      }
    } else {
      this.Archived = null
    }
    if (other.Deleted != null) {
      if (other.Deleted instanceof Date) {
        this.Deleted = new Date(other.Deleted.getTime())
      } else {
        this.Deleted = new Date(Math.round(other.Deleted / 1000000))
      }
    } else {
      this.Deleted = null
    }
    if (other.OpenPrice != null) {
      this.OpenPrice = other.OpenPrice
    } else {
      this.OpenPrice = null
    }
    if (other.ClosePrice != null) {
      this.ClosePrice = other.ClosePrice
    } else {
      this.ClosePrice = null
    }
    if (other.CloseAmount != null) {
      this.CloseAmount = other.CloseAmount
    } else {
      this.CloseAmount = null
    }
    if (other.OpenMarginRate != null) {
      this.OpenMarginRate = other.OpenMarginRate
    } else {
      this.OpenMarginRate = null
    }
    if (other.Commission != null) {
      this.Commission = other.Commission
    } else {
      this.Commission = null
    }
    if (other.Swap != null) {
      this.Swap = other.Swap
    } else {
      this.Swap = null
    }
    if (other.OpenMarkup != null) {
      this.OpenMarkup = UInt64.fromNumber(other.OpenMarkup)
    } else {
      this.OpenMarkup = null
    }
    if (other.GatewayId != null) {
      this.GatewayId = new UUID(other.GatewayId)
    } else {
      this.GatewayId = null
    }
    if (other.CloseProfit != null) {
      this.CloseProfit = other.CloseProfit
    } else {
      this.CloseProfit = null
    }
    if (other.Opened != null) {
      if (other.Opened instanceof Date) {
        this.Opened = new Date(other.Opened.getTime())
      } else {
        this.Opened = new Date(Math.round(other.Opened / 1000000))
      }
    } else {
      this.Opened = null
    }
    if (other.Closed != null) {
      if (other.Closed instanceof Date) {
        this.Closed = new Date(other.Closed.getTime())
      } else {
        this.Closed = new Date(Math.round(other.Closed / 1000000))
      }
    } else {
      this.Closed = null
    }
    if (other.OpenAccountMargin != null) {
      this.OpenAccountMargin = other.OpenAccountMargin
    } else {
      this.OpenAccountMargin = null
    }
    if (other.OpenAccountEquity != null) {
      this.OpenAccountEquity = other.OpenAccountEquity
    } else {
      this.OpenAccountEquity = null
    }
    if (other.OpenAccountCredit != null) {
      this.OpenAccountCredit = other.OpenAccountCredit
    } else {
      this.OpenAccountCredit = null
    }
    if (other.OpenAmount != null) {
      this.OpenAmount = other.OpenAmount
    } else {
      this.OpenAmount = null
    }
    if (other.OrderRequestSourceType != null) {
      this.OrderRequestSourceType = OrderRequestSourceType.fromObject(other.OrderRequestSourceType)
    } else {
      this.OrderRequestSourceType = null
    }
    if (other.IsCreatedViaQuickTrading != null) {
      this.IsCreatedViaQuickTrading = other.IsCreatedViaQuickTrading
    } else {
      this.IsCreatedViaQuickTrading = null
    }
    if (other.IsCreatedViaFastTrader != null) {
      this.IsCreatedViaFastTrader = other.IsCreatedViaFastTrader
    } else {
      this.IsCreatedViaFastTrader = null
    }
    if (other.SocialTradingOrderType != null) {
      this.SocialTradingOrderType = SocialTradingOrderType.fromObject(other.SocialTradingOrderType)
    } else {
      this.SocialTradingOrderType = null
    }
    if (other.SocialTradingOrderInvestor != null) {
      this.SocialTradingOrderInvestor = SocialTradingOrderInvestor.fromObject(other.SocialTradingOrderInvestor)
    } else {
      this.SocialTradingOrderInvestor = null
    }
    if (other.SocialTradingOrderMaster != null) {
      this.SocialTradingOrderMaster = SocialTradingOrderMaster.fromObject(other.SocialTradingOrderMaster)
    } else {
      this.SocialTradingOrderMaster = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Order}
   * @returns {!Order} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Order}
   * @param {!Order} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Order)) {
      throw new TypeError('Instance of Order is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.OrderId !== other.OrderId) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Order}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      OrderId: ((this.OrderId != null) ? this.OrderId.toNumber() : null),
      ParentOrderId: ((this.ParentOrderId != null) ? this.ParentOrderId.toNumber() : null),
      ClientOrderId: ((this.ClientOrderId != null) ? this.ClientOrderId : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      OrderSide: ((this.OrderSide != null) ? this.OrderSide : null),
      OrderType: ((this.OrderType != null) ? this.OrderType : null),
      OrderInitialType: ((this.OrderInitialType != null) ? this.OrderInitialType : null),
      OrderTimeInForce: ((this.OrderTimeInForce != null) ? this.OrderTimeInForce : null),
      OrderStatus: ((this.OrderStatus != null) ? this.OrderStatus : null),
      Price: ((this.Price != null) ? this.Price : null),
      StopPrice: ((this.StopPrice != null) ? this.StopPrice : null),
      TakeProfit: ((this.TakeProfit != null) ? this.TakeProfit : null),
      StopLoss: ((this.StopLoss != null) ? this.StopLoss : null),
      Amount: ((this.Amount != null) ? this.Amount : null),
      ExecutedAmount: ((this.ExecutedAmount != null) ? this.ExecutedAmount : null),
      RemainingAmount: ((this.RemainingAmount != null) ? this.RemainingAmount : null),
      Expiration: ((this.Expiration != null) ? (this.Expiration.getTime() * 1000000) : null),
      AccountComment: ((this.AccountComment != null) ? this.AccountComment : null),
      ManagerComment: ((this.ManagerComment != null) ? this.ManagerComment : null),
      Magic: ((this.Magic != null) ? this.Magic : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null),
      Modified: ((this.Modified != null) ? (this.Modified.getTime() * 1000000) : null),
      Archived: ((this.Archived != null) ? (this.Archived.getTime() * 1000000) : null),
      Deleted: ((this.Deleted != null) ? (this.Deleted.getTime() * 1000000) : null),
      OpenPrice: ((this.OpenPrice != null) ? this.OpenPrice : null),
      ClosePrice: ((this.ClosePrice != null) ? this.ClosePrice : null),
      CloseAmount: ((this.CloseAmount != null) ? this.CloseAmount : null),
      OpenMarginRate: ((this.OpenMarginRate != null) ? this.OpenMarginRate : null),
      Commission: ((this.Commission != null) ? this.Commission : null),
      Swap: ((this.Swap != null) ? this.Swap : null),
      OpenMarkup: ((this.OpenMarkup != null) ? this.OpenMarkup.toNumber() : null),
      GatewayId: ((this.GatewayId != null) ? this.GatewayId.toString() : null),
      CloseProfit: ((this.CloseProfit != null) ? this.CloseProfit : null),
      Opened: ((this.Opened != null) ? (this.Opened.getTime() * 1000000) : null),
      Closed: ((this.Closed != null) ? (this.Closed.getTime() * 1000000) : null),
      OpenAccountMargin: ((this.OpenAccountMargin != null) ? this.OpenAccountMargin : null),
      OpenAccountEquity: ((this.OpenAccountEquity != null) ? this.OpenAccountEquity : null),
      OpenAccountCredit: ((this.OpenAccountCredit != null) ? this.OpenAccountCredit : null),
      OpenAmount: ((this.OpenAmount != null) ? this.OpenAmount : null),
      OrderRequestSourceType: ((this.OrderRequestSourceType != null) ? this.OrderRequestSourceType : null),
      IsCreatedViaQuickTrading: ((this.IsCreatedViaQuickTrading != null) ? this.IsCreatedViaQuickTrading : null),
      IsCreatedViaFastTrader: ((this.IsCreatedViaFastTrader != null) ? this.IsCreatedViaFastTrader : null),
      SocialTradingOrderType: ((this.SocialTradingOrderType != null) ? this.SocialTradingOrderType : null),
      SocialTradingOrderInvestor: ((this.SocialTradingOrderInvestor != null) ? this.SocialTradingOrderInvestor : null),
      SocialTradingOrderMaster: ((this.SocialTradingOrderMaster != null) ? this.SocialTradingOrderMaster : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Order.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Order} other Object value
   * @returns {!Order} Created struct
   */
  static fromObject (other) {
    return new Order().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Order}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Order.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Order}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 52
  }
}

export { Order };

/**
 * Fast Binary Encoding Order field model
 */
class FieldModelOrder extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.OrderId = new fbe.FieldModelUInt64(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.ParentOrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize), buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize)
    this.ClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ParentOrderId.fbeOffset + this.ParentOrderId.fbeSize), buffer, this.ParentOrderId.fbeOffset + this.ParentOrderId.fbeSize)
    this.ActualVersion = new fbe.FieldModelUInt64(buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize)
    this.SymbolId = new fbe.FieldModelUUID(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.OrderSide = new FieldModelOrderSide(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.OrderType = new FieldModelOrderType(buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize)
    this.OrderInitialType = new FieldModelOrderType(buffer, this.OrderType.fbeOffset + this.OrderType.fbeSize)
    this.OrderTimeInForce = new FieldModelOrderTimeInForce(buffer, this.OrderInitialType.fbeOffset + this.OrderInitialType.fbeSize)
    this.OrderStatus = new FieldModelOrderStatus(buffer, this.OrderTimeInForce.fbeOffset + this.OrderTimeInForce.fbeSize)
    this.Price = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OrderStatus.fbeOffset + this.OrderStatus.fbeSize), buffer, this.OrderStatus.fbeOffset + this.OrderStatus.fbeSize)
    this.StopPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Price.fbeOffset + this.Price.fbeSize), buffer, this.Price.fbeOffset + this.Price.fbeSize)
    this.TakeProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize), buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize)
    this.StopLoss = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize), buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize)
    this.Amount = new fbe.FieldModelDouble(buffer, this.StopLoss.fbeOffset + this.StopLoss.fbeSize)
    this.ExecutedAmount = new fbe.FieldModelDouble(buffer, this.Amount.fbeOffset + this.Amount.fbeSize)
    this.RemainingAmount = new fbe.FieldModelDouble(buffer, this.ExecutedAmount.fbeOffset + this.ExecutedAmount.fbeSize)
    this.Expiration = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.RemainingAmount.fbeOffset + this.RemainingAmount.fbeSize), buffer, this.RemainingAmount.fbeOffset + this.RemainingAmount.fbeSize)
    this.AccountComment = new fbe.FieldModelString(buffer, this.Expiration.fbeOffset + this.Expiration.fbeSize)
    this.ManagerComment = new fbe.FieldModelString(buffer, this.AccountComment.fbeOffset + this.AccountComment.fbeSize)
    this.Magic = new fbe.FieldModelInt32(buffer, this.ManagerComment.fbeOffset + this.ManagerComment.fbeSize)
    this.Created = new fbe.FieldModelTimestamp(buffer, this.Magic.fbeOffset + this.Magic.fbeSize)
    this.Modified = new fbe.FieldModelTimestamp(buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.Archived = new fbe.FieldModelTimestamp(buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.Deleted = new fbe.FieldModelTimestamp(buffer, this.Archived.fbeOffset + this.Archived.fbeSize)
    this.OpenPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize), buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize)
    this.ClosePrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OpenPrice.fbeOffset + this.OpenPrice.fbeSize), buffer, this.OpenPrice.fbeOffset + this.OpenPrice.fbeSize)
    this.CloseAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize), buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize)
    this.OpenMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.CloseAmount.fbeOffset + this.CloseAmount.fbeSize), buffer, this.CloseAmount.fbeOffset + this.CloseAmount.fbeSize)
    this.Commission = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OpenMarginRate.fbeOffset + this.OpenMarginRate.fbeSize), buffer, this.OpenMarginRate.fbeOffset + this.OpenMarginRate.fbeSize)
    this.Swap = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Commission.fbeOffset + this.Commission.fbeSize), buffer, this.Commission.fbeOffset + this.Commission.fbeSize)
    this.OpenMarkup = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.Swap.fbeOffset + this.Swap.fbeSize), buffer, this.Swap.fbeOffset + this.Swap.fbeSize)
    this.GatewayId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.OpenMarkup.fbeOffset + this.OpenMarkup.fbeSize), buffer, this.OpenMarkup.fbeOffset + this.OpenMarkup.fbeSize)
    this.CloseProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.GatewayId.fbeOffset + this.GatewayId.fbeSize), buffer, this.GatewayId.fbeOffset + this.GatewayId.fbeSize)
    this.Opened = new fbe.FieldModelTimestamp(buffer, this.CloseProfit.fbeOffset + this.CloseProfit.fbeSize)
    this.Closed = new fbe.FieldModelTimestamp(buffer, this.Opened.fbeOffset + this.Opened.fbeSize)
    this.OpenAccountMargin = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Closed.fbeOffset + this.Closed.fbeSize), buffer, this.Closed.fbeOffset + this.Closed.fbeSize)
    this.OpenAccountEquity = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OpenAccountMargin.fbeOffset + this.OpenAccountMargin.fbeSize), buffer, this.OpenAccountMargin.fbeOffset + this.OpenAccountMargin.fbeSize)
    this.OpenAccountCredit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OpenAccountEquity.fbeOffset + this.OpenAccountEquity.fbeSize), buffer, this.OpenAccountEquity.fbeOffset + this.OpenAccountEquity.fbeSize)
    this.OpenAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OpenAccountCredit.fbeOffset + this.OpenAccountCredit.fbeSize), buffer, this.OpenAccountCredit.fbeOffset + this.OpenAccountCredit.fbeSize)
    this.OrderRequestSourceType = new FieldModelOrderRequestSourceType(buffer, this.OpenAmount.fbeOffset + this.OpenAmount.fbeSize)
    this.IsCreatedViaQuickTrading = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.OrderRequestSourceType.fbeOffset + this.OrderRequestSourceType.fbeSize), buffer, this.OrderRequestSourceType.fbeOffset + this.OrderRequestSourceType.fbeSize)
    this.IsCreatedViaFastTrader = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.IsCreatedViaQuickTrading.fbeOffset + this.IsCreatedViaQuickTrading.fbeSize), buffer, this.IsCreatedViaQuickTrading.fbeOffset + this.IsCreatedViaQuickTrading.fbeSize)
    this.SocialTradingOrderType = new FieldModelSocialTradingOrderType(buffer, this.IsCreatedViaFastTrader.fbeOffset + this.IsCreatedViaFastTrader.fbeSize)
    this.SocialTradingOrderInvestor = new fbe.FieldModelOptional(new FieldModelSocialTradingOrderInvestor(buffer, this.SocialTradingOrderType.fbeOffset + this.SocialTradingOrderType.fbeSize), buffer, this.SocialTradingOrderType.fbeOffset + this.SocialTradingOrderType.fbeSize)
    this.SocialTradingOrderMaster = new fbe.FieldModelOptional(new FieldModelSocialTradingOrderMaster(buffer, this.SocialTradingOrderInvestor.fbeOffset + this.SocialTradingOrderInvestor.fbeSize), buffer, this.SocialTradingOrderInvestor.fbeOffset + this.SocialTradingOrderInvestor.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrder}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrder}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.OrderId.fbeSize + this.ParentOrderId.fbeSize + this.ClientOrderId.fbeSize + this.ActualVersion.fbeSize + this.SymbolId.fbeSize + this.OrderSide.fbeSize + this.OrderType.fbeSize + this.OrderInitialType.fbeSize + this.OrderTimeInForce.fbeSize + this.OrderStatus.fbeSize + this.Price.fbeSize + this.StopPrice.fbeSize + this.TakeProfit.fbeSize + this.StopLoss.fbeSize + this.Amount.fbeSize + this.ExecutedAmount.fbeSize + this.RemainingAmount.fbeSize + this.Expiration.fbeSize + this.AccountComment.fbeSize + this.ManagerComment.fbeSize + this.Magic.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.Archived.fbeSize + this.Deleted.fbeSize + this.OpenPrice.fbeSize + this.ClosePrice.fbeSize + this.CloseAmount.fbeSize + this.OpenMarginRate.fbeSize + this.Commission.fbeSize + this.Swap.fbeSize + this.OpenMarkup.fbeSize + this.GatewayId.fbeSize + this.CloseProfit.fbeSize + this.Opened.fbeSize + this.Closed.fbeSize + this.OpenAccountMargin.fbeSize + this.OpenAccountEquity.fbeSize + this.OpenAccountCredit.fbeSize + this.OpenAmount.fbeSize + this.OrderRequestSourceType.fbeSize + this.IsCreatedViaQuickTrading.fbeSize + this.IsCreatedViaFastTrader.fbeSize + this.SocialTradingOrderType.fbeSize + this.SocialTradingOrderInvestor.fbeSize + this.SocialTradingOrderMaster.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrder}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.OrderId.fbeExtra + this.ParentOrderId.fbeExtra + this.ClientOrderId.fbeExtra + this.ActualVersion.fbeExtra + this.SymbolId.fbeExtra + this.OrderSide.fbeExtra + this.OrderType.fbeExtra + this.OrderInitialType.fbeExtra + this.OrderTimeInForce.fbeExtra + this.OrderStatus.fbeExtra + this.Price.fbeExtra + this.StopPrice.fbeExtra + this.TakeProfit.fbeExtra + this.StopLoss.fbeExtra + this.Amount.fbeExtra + this.ExecutedAmount.fbeExtra + this.RemainingAmount.fbeExtra + this.Expiration.fbeExtra + this.AccountComment.fbeExtra + this.ManagerComment.fbeExtra + this.Magic.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.Archived.fbeExtra + this.Deleted.fbeExtra + this.OpenPrice.fbeExtra + this.ClosePrice.fbeExtra + this.CloseAmount.fbeExtra + this.OpenMarginRate.fbeExtra + this.Commission.fbeExtra + this.Swap.fbeExtra + this.OpenMarkup.fbeExtra + this.GatewayId.fbeExtra + this.CloseProfit.fbeExtra + this.Opened.fbeExtra + this.Closed.fbeExtra + this.OpenAccountMargin.fbeExtra + this.OpenAccountEquity.fbeExtra + this.OpenAccountCredit.fbeExtra + this.OpenAmount.fbeExtra + this.OrderRequestSourceType.fbeExtra + this.IsCreatedViaQuickTrading.fbeExtra + this.IsCreatedViaFastTrader.fbeExtra + this.SocialTradingOrderType.fbeExtra + this.SocialTradingOrderInvestor.fbeExtra + this.SocialTradingOrderMaster.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrder}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrder.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrder}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 52
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrder}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrder}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.OrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ParentOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ParentOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParentOrderId.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderInitialType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderInitialType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderInitialType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderTimeInForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.OrderStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStatus.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Price.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TakeProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopLoss.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Amount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.ExecutedAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExecutedAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutedAmount.fbeSize

    if ((fbeCurrentSize + this.RemainingAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RemainingAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RemainingAmount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Expiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.AccountComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountComment.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Magic.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Archived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deleted.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.OpenPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.CloseAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CloseAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseAmount.fbeSize

    if ((fbeCurrentSize + this.OpenMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenMarginRate.fbeSize

    if ((fbeCurrentSize + this.Commission.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Commission.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Commission.fbeSize

    if ((fbeCurrentSize + this.Swap.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Swap.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Swap.fbeSize

    if ((fbeCurrentSize + this.OpenMarkup.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenMarkup.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenMarkup.fbeSize

    if ((fbeCurrentSize + this.GatewayId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.CloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseProfit.fbeSize

    if ((fbeCurrentSize + this.Opened.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Opened.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Opened.fbeSize

    if ((fbeCurrentSize + this.Closed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Closed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Closed.fbeSize

    if ((fbeCurrentSize + this.OpenAccountMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenAccountMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAccountMargin.fbeSize

    if ((fbeCurrentSize + this.OpenAccountEquity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenAccountEquity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAccountEquity.fbeSize

    if ((fbeCurrentSize + this.OpenAccountCredit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenAccountCredit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAccountCredit.fbeSize

    if ((fbeCurrentSize + this.OpenAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAmount.fbeSize

    if ((fbeCurrentSize + this.OrderRequestSourceType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderRequestSourceType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderRequestSourceType.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaQuickTrading.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsCreatedViaQuickTrading.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaQuickTrading.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaFastTrader.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsCreatedViaFastTrader.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaFastTrader.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingOrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderInvestor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingOrderInvestor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderInvestor.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderMaster.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingOrderMaster.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderMaster.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrder}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrder}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrder}
   * @param {!Order} fbeValue Default value, defaults is new Order()
   * @returns {!Order} Order value
   */
  get (fbeValue = new Order()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrder}
   * @param {!Order} fbeValue Order value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.OrderId.fbeSize) <= fbeStructSize) {
      fbeValue.OrderId = this.OrderId.get()
    } else {
      fbeValue.OrderId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ParentOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ParentOrderId = this.ParentOrderId.get()
    } else {
      fbeValue.ParentOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParentOrderId.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientOrderId = this.ClientOrderId.get()
    } else {
      fbeValue.ClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) <= fbeStructSize) {
      fbeValue.OrderSide = this.OrderSide.get()
    } else {
      fbeValue.OrderSide = new OrderSide()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderType = this.OrderType.get()
    } else {
      fbeValue.OrderType = new OrderType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderInitialType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderInitialType = this.OrderInitialType.get()
    } else {
      fbeValue.OrderInitialType = new OrderType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderInitialType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) <= fbeStructSize) {
      fbeValue.OrderTimeInForce = this.OrderTimeInForce.get()
    } else {
      fbeValue.OrderTimeInForce = new OrderTimeInForce()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.OrderStatus.fbeSize) <= fbeStructSize) {
      fbeValue.OrderStatus = this.OrderStatus.get()
    } else {
      fbeValue.OrderStatus = new OrderStatus()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStatus.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) <= fbeStructSize) {
      fbeValue.Price = this.Price.get()
    } else {
      fbeValue.Price = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) <= fbeStructSize) {
      fbeValue.StopPrice = this.StopPrice.get()
    } else {
      fbeValue.StopPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TakeProfit = this.TakeProfit.get()
    } else {
      fbeValue.TakeProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) <= fbeStructSize) {
      fbeValue.StopLoss = this.StopLoss.get()
    } else {
      fbeValue.StopLoss = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) <= fbeStructSize) {
      fbeValue.Amount = this.Amount.get()
    } else {
      fbeValue.Amount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.ExecutedAmount.fbeSize) <= fbeStructSize) {
      fbeValue.ExecutedAmount = this.ExecutedAmount.get()
    } else {
      fbeValue.ExecutedAmount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutedAmount.fbeSize

    if ((fbeCurrentSize + this.RemainingAmount.fbeSize) <= fbeStructSize) {
      fbeValue.RemainingAmount = this.RemainingAmount.get()
    } else {
      fbeValue.RemainingAmount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RemainingAmount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) <= fbeStructSize) {
      fbeValue.Expiration = this.Expiration.get()
    } else {
      fbeValue.Expiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.AccountComment.fbeSize) <= fbeStructSize) {
      fbeValue.AccountComment = this.AccountComment.get()
    } else {
      fbeValue.AccountComment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountComment.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerComment = this.ManagerComment.get()
    } else {
      fbeValue.ManagerComment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) <= fbeStructSize) {
      fbeValue.Magic = this.Magic.get()
    } else {
      fbeValue.Magic = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) <= fbeStructSize) {
      fbeValue.Archived = this.Archived.get()
    } else {
      fbeValue.Archived = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) <= fbeStructSize) {
      fbeValue.Deleted = this.Deleted.get()
    } else {
      fbeValue.Deleted = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.OpenPrice.fbeSize) <= fbeStructSize) {
      fbeValue.OpenPrice = this.OpenPrice.get()
    } else {
      fbeValue.OpenPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.ClosePrice = this.ClosePrice.get()
    } else {
      fbeValue.ClosePrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.CloseAmount.fbeSize) <= fbeStructSize) {
      fbeValue.CloseAmount = this.CloseAmount.get()
    } else {
      fbeValue.CloseAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseAmount.fbeSize

    if ((fbeCurrentSize + this.OpenMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.OpenMarginRate = this.OpenMarginRate.get()
    } else {
      fbeValue.OpenMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenMarginRate.fbeSize

    if ((fbeCurrentSize + this.Commission.fbeSize) <= fbeStructSize) {
      fbeValue.Commission = this.Commission.get()
    } else {
      fbeValue.Commission = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Commission.fbeSize

    if ((fbeCurrentSize + this.Swap.fbeSize) <= fbeStructSize) {
      fbeValue.Swap = this.Swap.get()
    } else {
      fbeValue.Swap = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Swap.fbeSize

    if ((fbeCurrentSize + this.OpenMarkup.fbeSize) <= fbeStructSize) {
      fbeValue.OpenMarkup = this.OpenMarkup.get()
    } else {
      fbeValue.OpenMarkup = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenMarkup.fbeSize

    if ((fbeCurrentSize + this.GatewayId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayId = this.GatewayId.get()
    } else {
      fbeValue.GatewayId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.CloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.CloseProfit = this.CloseProfit.get()
    } else {
      fbeValue.CloseProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseProfit.fbeSize

    if ((fbeCurrentSize + this.Opened.fbeSize) <= fbeStructSize) {
      fbeValue.Opened = this.Opened.get()
    } else {
      fbeValue.Opened = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Opened.fbeSize

    if ((fbeCurrentSize + this.Closed.fbeSize) <= fbeStructSize) {
      fbeValue.Closed = this.Closed.get()
    } else {
      fbeValue.Closed = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Closed.fbeSize

    if ((fbeCurrentSize + this.OpenAccountMargin.fbeSize) <= fbeStructSize) {
      fbeValue.OpenAccountMargin = this.OpenAccountMargin.get()
    } else {
      fbeValue.OpenAccountMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAccountMargin.fbeSize

    if ((fbeCurrentSize + this.OpenAccountEquity.fbeSize) <= fbeStructSize) {
      fbeValue.OpenAccountEquity = this.OpenAccountEquity.get()
    } else {
      fbeValue.OpenAccountEquity = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAccountEquity.fbeSize

    if ((fbeCurrentSize + this.OpenAccountCredit.fbeSize) <= fbeStructSize) {
      fbeValue.OpenAccountCredit = this.OpenAccountCredit.get()
    } else {
      fbeValue.OpenAccountCredit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAccountCredit.fbeSize

    if ((fbeCurrentSize + this.OpenAmount.fbeSize) <= fbeStructSize) {
      fbeValue.OpenAmount = this.OpenAmount.get()
    } else {
      fbeValue.OpenAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenAmount.fbeSize

    if ((fbeCurrentSize + this.OrderRequestSourceType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderRequestSourceType = this.OrderRequestSourceType.get()
    } else {
      fbeValue.OrderRequestSourceType = new OrderRequestSourceType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderRequestSourceType.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaQuickTrading.fbeSize) <= fbeStructSize) {
      fbeValue.IsCreatedViaQuickTrading = this.IsCreatedViaQuickTrading.get()
    } else {
      fbeValue.IsCreatedViaQuickTrading = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaQuickTrading.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaFastTrader.fbeSize) <= fbeStructSize) {
      fbeValue.IsCreatedViaFastTrader = this.IsCreatedViaFastTrader.get()
    } else {
      fbeValue.IsCreatedViaFastTrader = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaFastTrader.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderType.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingOrderType = this.SocialTradingOrderType.get()
    } else {
      fbeValue.SocialTradingOrderType = new SocialTradingOrderType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderInvestor.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingOrderInvestor = this.SocialTradingOrderInvestor.get()
    } else {
      fbeValue.SocialTradingOrderInvestor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderInvestor.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderMaster.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingOrderMaster = this.SocialTradingOrderMaster.get()
    } else {
      fbeValue.SocialTradingOrderMaster = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderMaster.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrder}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrder}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrder}
   * @param {!Order} fbeValue Order value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrder}
   * @param {Order} fbeValue Order value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.OrderId.set(fbeValue.OrderId)
    this.ParentOrderId.set(fbeValue.ParentOrderId)
    this.ClientOrderId.set(fbeValue.ClientOrderId)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.SymbolId.set(fbeValue.SymbolId)
    this.OrderSide.set(fbeValue.OrderSide)
    this.OrderType.set(fbeValue.OrderType)
    this.OrderInitialType.set(fbeValue.OrderInitialType)
    this.OrderTimeInForce.set(fbeValue.OrderTimeInForce)
    this.OrderStatus.set(fbeValue.OrderStatus)
    this.Price.set(fbeValue.Price)
    this.StopPrice.set(fbeValue.StopPrice)
    this.TakeProfit.set(fbeValue.TakeProfit)
    this.StopLoss.set(fbeValue.StopLoss)
    this.Amount.set(fbeValue.Amount)
    this.ExecutedAmount.set(fbeValue.ExecutedAmount)
    this.RemainingAmount.set(fbeValue.RemainingAmount)
    this.Expiration.set(fbeValue.Expiration)
    this.AccountComment.set(fbeValue.AccountComment)
    this.ManagerComment.set(fbeValue.ManagerComment)
    this.Magic.set(fbeValue.Magic)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.Archived.set(fbeValue.Archived)
    this.Deleted.set(fbeValue.Deleted)
    this.OpenPrice.set(fbeValue.OpenPrice)
    this.ClosePrice.set(fbeValue.ClosePrice)
    this.CloseAmount.set(fbeValue.CloseAmount)
    this.OpenMarginRate.set(fbeValue.OpenMarginRate)
    this.Commission.set(fbeValue.Commission)
    this.Swap.set(fbeValue.Swap)
    this.OpenMarkup.set(fbeValue.OpenMarkup)
    this.GatewayId.set(fbeValue.GatewayId)
    this.CloseProfit.set(fbeValue.CloseProfit)
    this.Opened.set(fbeValue.Opened)
    this.Closed.set(fbeValue.Closed)
    this.OpenAccountMargin.set(fbeValue.OpenAccountMargin)
    this.OpenAccountEquity.set(fbeValue.OpenAccountEquity)
    this.OpenAccountCredit.set(fbeValue.OpenAccountCredit)
    this.OpenAmount.set(fbeValue.OpenAmount)
    this.OrderRequestSourceType.set(fbeValue.OrderRequestSourceType)
    this.IsCreatedViaQuickTrading.set(fbeValue.IsCreatedViaQuickTrading)
    this.IsCreatedViaFastTrader.set(fbeValue.IsCreatedViaFastTrader)
    this.SocialTradingOrderType.set(fbeValue.SocialTradingOrderType)
    this.SocialTradingOrderInvestor.set(fbeValue.SocialTradingOrderInvestor)
    this.SocialTradingOrderMaster.set(fbeValue.SocialTradingOrderMaster)
  }
}

export { FieldModelOrder };

/**
 * Fast Binary Encoding Order model
 */
class OrderModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrder(this.buffer, 4)
  }

  /**
   * Get the Order model
   * @this {!OrderModel}
   * @returns {!FieldModelOrder} model Order model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrder.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderModel}
   * @param {!Order} value Order value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderModel}
   * @param {!Order} value Order value, defaults is new Order()
   * @return {!object} Deserialized Order value and its size
   */
  deserialize (value = new Order()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Order(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Order(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderModel}
   * @param {!number} prev Previous Order model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderModel };
