
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as history from '../history'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * RejectDetail enum
 */
class RejectDetail {
  /**
   * Initialize enum with a given value
   * @param {RejectDetail|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof RejectDetail) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!RejectDetail}
   * @param {!RejectDetail} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof RejectDetail)) {
      throw new TypeError('Instance of RejectDetail is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!RejectDetail}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!RejectDetail}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === RejectDetail.ACCOUNT_GROUP_NAME.value) {
      return 'ACCOUNT_GROUP_NAME'
    }
    if (this.value === RejectDetail.ACCOUNT_LOGIN.value) {
      return 'ACCOUNT_LOGIN'
    }
    if (this.value === RejectDetail.ACCOUNT_NAME.value) {
      return 'ACCOUNT_NAME'
    }
    if (this.value === RejectDetail.SYMBOL_GROUP_NAME.value) {
      return 'SYMBOL_GROUP_NAME'
    }
    if (this.value === RejectDetail.SYMBOL_NAME.value) {
      return 'SYMBOL_NAME'
    }
    if (this.value === RejectDetail.SYMBOL_ID.value) {
      return 'SYMBOL_ID'
    }
    if (this.value === RejectDetail.ORDER_ID.value) {
      return 'ORDER_ID'
    }
    if (this.value === RejectDetail.ORDER_SIDE.value) {
      return 'ORDER_SIDE'
    }
    if (this.value === RejectDetail.ORDER_TYPE.value) {
      return 'ORDER_TYPE'
    }
    if (this.value === RejectDetail.ORDER_STATUS.value) {
      return 'ORDER_STATUS'
    }
    if (this.value === RejectDetail.ORDER_LIMIT_PRICE.value) {
      return 'ORDER_LIMIT_PRICE'
    }
    if (this.value === RejectDetail.ORDER_STOP_PRICE.value) {
      return 'ORDER_STOP_PRICE'
    }
    if (this.value === RejectDetail.ORDER_OPEN_PRICE.value) {
      return 'ORDER_OPEN_PRICE'
    }
    if (this.value === RejectDetail.ORDER_TP_PRICE.value) {
      return 'ORDER_TP_PRICE'
    }
    if (this.value === RejectDetail.ORDER_SL_PRICE.value) {
      return 'ORDER_SL_PRICE'
    }
    if (this.value === RejectDetail.ORDER_PENDING_COUNT.value) {
      return 'ORDER_PENDING_COUNT'
    }
    if (this.value === RejectDetail.ORDER_COUNT.value) {
      return 'ORDER_COUNT'
    }
    if (this.value === RejectDetail.CURRENT_BID_PRICE.value) {
      return 'CURRENT_BID_PRICE'
    }
    if (this.value === RejectDetail.CURRENT_ASK_PRICE.value) {
      return 'CURRENT_ASK_PRICE'
    }
    if (this.value === RejectDetail.CURRENT_OPEN_PRICE.value) {
      return 'CURRENT_OPEN_PRICE'
    }
    if (this.value === RejectDetail.CURRENT_CLOSE_PRICE.value) {
      return 'CURRENT_CLOSE_PRICE'
    }
    if (this.value === RejectDetail.PASSWORD_INVALID_LENGTH.value) {
      return 'PASSWORD_INVALID_LENGTH'
    }
    if (this.value === RejectDetail.PASSWORD_INVALID_SYMBOL.value) {
      return 'PASSWORD_INVALID_SYMBOL'
    }
    if (this.value === RejectDetail.PASSWORD_NO_LOWER.value) {
      return 'PASSWORD_NO_LOWER'
    }
    if (this.value === RejectDetail.PASSWORD_NO_UPPER.value) {
      return 'PASSWORD_NO_UPPER'
    }
    if (this.value === RejectDetail.PASSWORD_NO_DIGITS.value) {
      return 'PASSWORD_NO_DIGITS'
    }
    if (this.value === RejectDetail.PASSWORD_NO_SPECIAL_SYMBOLS.value) {
      return 'PASSWORD_NO_SPECIAL_SYMBOLS'
    }
    if (this.value === RejectDetail.CURRENCY_ID.value) {
      return 'CURRENCY_ID'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!RejectDetail}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!RejectDetail}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!RejectDetail} Created enum
   */
  static fromObject (other) {
    return new RejectDetail(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
RejectDetail.ACCOUNT_GROUP_NAME = new RejectDetail(100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ACCOUNT_LOGIN = new RejectDetail(200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ACCOUNT_NAME = new RejectDetail(200 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.SYMBOL_GROUP_NAME = new RejectDetail(300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.SYMBOL_NAME = new RejectDetail(400 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.SYMBOL_ID = new RejectDetail(400 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_ID = new RejectDetail(500 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_SIDE = new RejectDetail(500 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_TYPE = new RejectDetail(500 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_STATUS = new RejectDetail(500 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_LIMIT_PRICE = new RejectDetail(500 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_STOP_PRICE = new RejectDetail(500 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_OPEN_PRICE = new RejectDetail(500 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_TP_PRICE = new RejectDetail(500 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_SL_PRICE = new RejectDetail(500 + 8)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_PENDING_COUNT = new RejectDetail(500 + 9)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.ORDER_COUNT = new RejectDetail(500 + 10)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.CURRENT_BID_PRICE = new RejectDetail(600 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.CURRENT_ASK_PRICE = new RejectDetail(600 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.CURRENT_OPEN_PRICE = new RejectDetail(600 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.CURRENT_CLOSE_PRICE = new RejectDetail(600 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.PASSWORD_INVALID_LENGTH = new RejectDetail(700 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.PASSWORD_INVALID_SYMBOL = new RejectDetail(700 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.PASSWORD_NO_LOWER = new RejectDetail(700 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.PASSWORD_NO_UPPER = new RejectDetail(700 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.PASSWORD_NO_DIGITS = new RejectDetail(700 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.PASSWORD_NO_SPECIAL_SYMBOLS = new RejectDetail(700 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectDetail.CURRENCY_ID = new RejectDetail(800 + 0)

export { RejectDetail };

/**
 * Fast Binary Encoding RejectDetail field model
 */
class FieldModelRejectDetail extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelRejectDetail}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelRejectDetail}
   * @param {RejectDetail=} defaults Default value, defaults is new RejectDetail()
   * @returns {!RejectDetail} Result value
   */
  get (defaults = new RejectDetail()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new RejectDetail(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelRejectDetail}
   * @param {!RejectDetail} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelRejectDetail };
