
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {TradeEventsGetResponse} from './TradeEventsGetResponse';
import {TradeEventType} from './TradeEventType';
import {FieldModelTradeEventType} from './TradeEventType';
import {ReasonCode} from './ReasonCode';
import {FieldModelReasonCode} from './ReasonCode';
import {RequestDirection} from '../core/RequestDirection';
import {FieldModelRequestDirection} from '../core/RequestDirection';

/**
 * TradeEventsGetRequest struct
 */
class TradeEventsGetRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {number=} sinceRecordId
   * @param {Date=} timeFrom
   * @param {Date=} timeTo
   * @param {UUID=} transactionId
   * @param {!Set=} includeTradeEventTypes
   * @param {!Set=} excludeTradeEventTypes
   * @param {ReasonCode=} reasonCode
   * @param {!RequestDirection=} requestDirection
   * @param {number=} limit
   * @param {UUID=} platformId
   * @param {number=} accountLogin
   * @constructor
   */
  constructor (parent = new Request(), sinceRecordId = null, timeFrom = null, timeTo = null, transactionId = null, includeTradeEventTypes = new Set(), excludeTradeEventTypes = new Set(), reasonCode = null, requestDirection = new RequestDirection(RequestDirection.FORWARD), limit = null, platformId = null, accountLogin = null) {
    super()
    super.copy(parent)
    this.SinceRecordId = sinceRecordId
    this.TimeFrom = timeFrom
    this.TimeTo = timeTo
    this.TransactionId = transactionId
    this.IncludeTradeEventTypes = includeTradeEventTypes
    this.ExcludeTradeEventTypes = excludeTradeEventTypes
    this.ReasonCode = reasonCode
    this.RequestDirection = requestDirection
    this.Limit = limit
    this.PlatformId = platformId
    this.AccountLogin = accountLogin
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradeEventsGetRequest}
   * @param {!TradeEventsGetRequest} other Other struct
   * @returns {!TradeEventsGetRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SinceRecordId != null) {
      this.SinceRecordId = UInt64.fromNumber(other.SinceRecordId)
    } else {
      this.SinceRecordId = null
    }
    if (other.TimeFrom != null) {
      if (other.TimeFrom instanceof Date) {
        this.TimeFrom = new Date(other.TimeFrom.getTime())
      } else {
        this.TimeFrom = new Date(Math.round(other.TimeFrom / 1000000))
      }
    } else {
      this.TimeFrom = null
    }
    if (other.TimeTo != null) {
      if (other.TimeTo instanceof Date) {
        this.TimeTo = new Date(other.TimeTo.getTime())
      } else {
        this.TimeTo = new Date(Math.round(other.TimeTo / 1000000))
      }
    } else {
      this.TimeTo = null
    }
    if (other.TransactionId != null) {
      this.TransactionId = new UUID(other.TransactionId)
    } else {
      this.TransactionId = null
    }
    if (other.IncludeTradeEventTypes != null) {
      this.IncludeTradeEventTypes = new Set()
      for (let item of other.IncludeTradeEventTypes) {
        if (item != null) {
          let tempItem
          tempItem = TradeEventType.fromObject(item)
          this.IncludeTradeEventTypes.add(tempItem)
        } else {
          this.IncludeTradeEventTypes.add(null)
        }
      }
    } else {
      this.IncludeTradeEventTypes = null
    }
    if (other.ExcludeTradeEventTypes != null) {
      this.ExcludeTradeEventTypes = new Set()
      for (let item of other.ExcludeTradeEventTypes) {
        if (item != null) {
          let tempItem
          tempItem = TradeEventType.fromObject(item)
          this.ExcludeTradeEventTypes.add(tempItem)
        } else {
          this.ExcludeTradeEventTypes.add(null)
        }
      }
    } else {
      this.ExcludeTradeEventTypes = null
    }
    if (other.ReasonCode != null) {
      this.ReasonCode = ReasonCode.fromObject(other.ReasonCode)
    } else {
      this.ReasonCode = null
    }
    if (other.RequestDirection != null) {
      this.RequestDirection = RequestDirection.fromObject(other.RequestDirection)
    } else {
      this.RequestDirection = null
    }
    if (other.Limit != null) {
      this.Limit = UInt64.fromNumber(other.Limit)
    } else {
      this.Limit = null
    }
    if (other.PlatformId != null) {
      this.PlatformId = new UUID(other.PlatformId)
    } else {
      this.PlatformId = null
    }
    if (other.AccountLogin != null) {
      this.AccountLogin = UInt64.fromNumber(other.AccountLogin)
    } else {
      this.AccountLogin = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradeEventsGetRequest}
   * @returns {!TradeEventsGetRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradeEventsGetRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradeEventsGetRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradeEventsGetRequest}
   * @param {!TradeEventsGetRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradeEventsGetRequest)) {
      throw new TypeError('Instance of TradeEventsGetRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradeEventsGetRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SinceRecordId: ((this.SinceRecordId != null) ? this.SinceRecordId.toNumber() : null),
      TimeFrom: ((this.TimeFrom != null) ? (this.TimeFrom.getTime() * 1000000) : null),
      TimeTo: ((this.TimeTo != null) ? (this.TimeTo.getTime() * 1000000) : null),
      TransactionId: ((this.TransactionId != null) ? this.TransactionId.toString() : null),
      IncludeTradeEventTypes: ((this.IncludeTradeEventTypes != null) ? Array.from(this.IncludeTradeEventTypes, item => ((item != null) ? item : null)) : null),
      ExcludeTradeEventTypes: ((this.ExcludeTradeEventTypes != null) ? Array.from(this.ExcludeTradeEventTypes, item => ((item != null) ? item : null)) : null),
      ReasonCode: ((this.ReasonCode != null) ? this.ReasonCode : null),
      RequestDirection: ((this.RequestDirection != null) ? this.RequestDirection : null),
      Limit: ((this.Limit != null) ? this.Limit.toNumber() : null),
      PlatformId: ((this.PlatformId != null) ? this.PlatformId.toString() : null),
      AccountLogin: ((this.AccountLogin != null) ? this.AccountLogin.toNumber() : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradeEventsGetRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradeEventsGetRequest} other Object value
   * @returns {!TradeEventsGetRequest} Created struct
   */
  static fromObject (other) {
    return new TradeEventsGetRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradeEventsGetRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradeEventsGetRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradeEventsGetRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4046
  }
}

export { TradeEventsGetRequest };

/**
 * Fast Binary Encoding TradeEventsGetRequest field model
 */
class FieldModelTradeEventsGetRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.SinceRecordId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.TimeFrom = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.SinceRecordId.fbeOffset + this.SinceRecordId.fbeSize), buffer, this.SinceRecordId.fbeOffset + this.SinceRecordId.fbeSize)
    this.TimeTo = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.TimeFrom.fbeOffset + this.TimeFrom.fbeSize), buffer, this.TimeFrom.fbeOffset + this.TimeFrom.fbeSize)
    this.TransactionId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.TimeTo.fbeOffset + this.TimeTo.fbeSize), buffer, this.TimeTo.fbeOffset + this.TimeTo.fbeSize)
    this.IncludeTradeEventTypes = new fbe.FieldModelSet(new FieldModelTradeEventType(buffer, this.TransactionId.fbeOffset + this.TransactionId.fbeSize), buffer, this.TransactionId.fbeOffset + this.TransactionId.fbeSize)
    this.ExcludeTradeEventTypes = new fbe.FieldModelSet(new FieldModelTradeEventType(buffer, this.IncludeTradeEventTypes.fbeOffset + this.IncludeTradeEventTypes.fbeSize), buffer, this.IncludeTradeEventTypes.fbeOffset + this.IncludeTradeEventTypes.fbeSize)
    this.ReasonCode = new fbe.FieldModelOptional(new FieldModelReasonCode(buffer, this.ExcludeTradeEventTypes.fbeOffset + this.ExcludeTradeEventTypes.fbeSize), buffer, this.ExcludeTradeEventTypes.fbeOffset + this.ExcludeTradeEventTypes.fbeSize)
    this.RequestDirection = new FieldModelRequestDirection(buffer, this.ReasonCode.fbeOffset + this.ReasonCode.fbeSize)
    this.Limit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.RequestDirection.fbeOffset + this.RequestDirection.fbeSize), buffer, this.RequestDirection.fbeOffset + this.RequestDirection.fbeSize)
    this.PlatformId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Limit.fbeOffset + this.Limit.fbeSize), buffer, this.Limit.fbeOffset + this.Limit.fbeSize)
    this.AccountLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize), buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SinceRecordId.fbeSize + this.TimeFrom.fbeSize + this.TimeTo.fbeSize + this.TransactionId.fbeSize + this.IncludeTradeEventTypes.fbeSize + this.ExcludeTradeEventTypes.fbeSize + this.ReasonCode.fbeSize + this.RequestDirection.fbeSize + this.Limit.fbeSize + this.PlatformId.fbeSize + this.AccountLogin.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SinceRecordId.fbeExtra + this.TimeFrom.fbeExtra + this.TimeTo.fbeExtra + this.TransactionId.fbeExtra + this.IncludeTradeEventTypes.fbeExtra + this.ExcludeTradeEventTypes.fbeExtra + this.ReasonCode.fbeExtra + this.RequestDirection.fbeExtra + this.Limit.fbeExtra + this.PlatformId.fbeExtra + this.AccountLogin.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradeEventsGetRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4046
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SinceRecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SinceRecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SinceRecordId.fbeSize

    if ((fbeCurrentSize + this.TimeFrom.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimeFrom.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeFrom.fbeSize

    if ((fbeCurrentSize + this.TimeTo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimeTo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeTo.fbeSize

    if ((fbeCurrentSize + this.TransactionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionId.fbeSize

    if ((fbeCurrentSize + this.IncludeTradeEventTypes.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IncludeTradeEventTypes.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IncludeTradeEventTypes.fbeSize

    if ((fbeCurrentSize + this.ExcludeTradeEventTypes.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExcludeTradeEventTypes.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExcludeTradeEventTypes.fbeSize

    if ((fbeCurrentSize + this.ReasonCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReasonCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReasonCode.fbeSize

    if ((fbeCurrentSize + this.RequestDirection.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestDirection.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestDirection.fbeSize

    if ((fbeCurrentSize + this.Limit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Limit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Limit.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {!TradeEventsGetRequest} fbeValue Default value, defaults is new TradeEventsGetRequest()
   * @returns {!TradeEventsGetRequest} TradeEventsGetRequest value
   */
  get (fbeValue = new TradeEventsGetRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {!TradeEventsGetRequest} fbeValue TradeEventsGetRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SinceRecordId.fbeSize) <= fbeStructSize) {
      fbeValue.SinceRecordId = this.SinceRecordId.get()
    } else {
      fbeValue.SinceRecordId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SinceRecordId.fbeSize

    if ((fbeCurrentSize + this.TimeFrom.fbeSize) <= fbeStructSize) {
      fbeValue.TimeFrom = this.TimeFrom.get()
    } else {
      fbeValue.TimeFrom = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeFrom.fbeSize

    if ((fbeCurrentSize + this.TimeTo.fbeSize) <= fbeStructSize) {
      fbeValue.TimeTo = this.TimeTo.get()
    } else {
      fbeValue.TimeTo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimeTo.fbeSize

    if ((fbeCurrentSize + this.TransactionId.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionId = this.TransactionId.get()
    } else {
      fbeValue.TransactionId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionId.fbeSize

    if ((fbeCurrentSize + this.IncludeTradeEventTypes.fbeSize) <= fbeStructSize) {
      this.IncludeTradeEventTypes.get(fbeValue.IncludeTradeEventTypes)
    } else {
      fbeValue.IncludeTradeEventTypes.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IncludeTradeEventTypes.fbeSize

    if ((fbeCurrentSize + this.ExcludeTradeEventTypes.fbeSize) <= fbeStructSize) {
      this.ExcludeTradeEventTypes.get(fbeValue.ExcludeTradeEventTypes)
    } else {
      fbeValue.ExcludeTradeEventTypes.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExcludeTradeEventTypes.fbeSize

    if ((fbeCurrentSize + this.ReasonCode.fbeSize) <= fbeStructSize) {
      fbeValue.ReasonCode = this.ReasonCode.get()
    } else {
      fbeValue.ReasonCode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReasonCode.fbeSize

    if ((fbeCurrentSize + this.RequestDirection.fbeSize) <= fbeStructSize) {
      fbeValue.RequestDirection = this.RequestDirection.get(new RequestDirection(RequestDirection.FORWARD))
    } else {
      fbeValue.RequestDirection = new RequestDirection(RequestDirection.FORWARD)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestDirection.fbeSize

    if ((fbeCurrentSize + this.Limit.fbeSize) <= fbeStructSize) {
      fbeValue.Limit = this.Limit.get()
    } else {
      fbeValue.Limit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Limit.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformId = this.PlatformId.get()
    } else {
      fbeValue.PlatformId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) <= fbeStructSize) {
      fbeValue.AccountLogin = this.AccountLogin.get()
    } else {
      fbeValue.AccountLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradeEventsGetRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {!TradeEventsGetRequest} fbeValue TradeEventsGetRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradeEventsGetRequest}
   * @param {TradeEventsGetRequest} fbeValue TradeEventsGetRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SinceRecordId.set(fbeValue.SinceRecordId)
    this.TimeFrom.set(fbeValue.TimeFrom)
    this.TimeTo.set(fbeValue.TimeTo)
    this.TransactionId.set(fbeValue.TransactionId)
    this.IncludeTradeEventTypes.set(fbeValue.IncludeTradeEventTypes)
    this.ExcludeTradeEventTypes.set(fbeValue.ExcludeTradeEventTypes)
    this.ReasonCode.set(fbeValue.ReasonCode)
    this.RequestDirection.set(fbeValue.RequestDirection)
    this.Limit.set(fbeValue.Limit)
    this.PlatformId.set(fbeValue.PlatformId)
    this.AccountLogin.set(fbeValue.AccountLogin)
  }
}

export { FieldModelTradeEventsGetRequest };

/**
 * Fast Binary Encoding TradeEventsGetRequest model
 */
class TradeEventsGetRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradeEventsGetRequest(this.buffer, 4)
  }

  /**
   * Get the TradeEventsGetRequest model
   * @this {!TradeEventsGetRequestModel}
   * @returns {!FieldModelTradeEventsGetRequest} model TradeEventsGetRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradeEventsGetRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradeEventsGetRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradeEventsGetRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradeEventsGetRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradeEventsGetRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradeEventsGetRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradeEventsGetRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradeEventsGetRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradeEventsGetRequestModel}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradeEventsGetRequestModel}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value, defaults is new TradeEventsGetRequest()
   * @return {!object} Deserialized TradeEventsGetRequest value and its size
   */
  deserialize (value = new TradeEventsGetRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradeEventsGetRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradeEventsGetRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradeEventsGetRequestModel}
   * @param {!number} prev Previous TradeEventsGetRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradeEventsGetRequestModel };
TradeEventsGetRequest.__has_response = true;
TradeEventsGetRequest.__response_class = TradeEventsGetResponse;
