
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {Order} from '../core/Order';
import {FieldModelOrder} from '../core/Order';
import {AccountMargin} from '../core/AccountMargin';
import {FieldModelAccountMargin} from '../core/AccountMargin';
import {TradeHistoryRecord} from '../core/TradeHistoryRecord';
import {FieldModelTradeHistoryRecord} from '../core/TradeHistoryRecord';
import {TradeEvent} from './TradeEvent';
import {FieldModelTradeEvent} from './TradeEvent';

/**
 * OrderDelete2Response struct
 */
class OrderDelete2Response extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!Order=} order
   * @param {AccountMargin=} accountMarginPrevious
   * @param {AccountMargin=} accountMarginCurrent
   * @param {!TradeHistoryRecord=} tradeHistoryRecord
   * @param {!Array=} tradeEvents
   * @constructor
   */
  constructor (parent = new Response(), order = new Order(), accountMarginPrevious = null, accountMarginCurrent = null, tradeHistoryRecord = new TradeHistoryRecord(), tradeEvents = []) {
    super()
    super.copy(parent)
    this.Order = order
    this.AccountMarginPrevious = accountMarginPrevious
    this.AccountMarginCurrent = accountMarginCurrent
    this.TradeHistoryRecord = tradeHistoryRecord
    this.TradeEvents = tradeEvents
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderDelete2Response}
   * @param {!OrderDelete2Response} other Other struct
   * @returns {!OrderDelete2Response} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Order != null) {
      this.Order = Order.fromObject(other.Order)
    } else {
      this.Order = null
    }
    if (other.AccountMarginPrevious != null) {
      this.AccountMarginPrevious = AccountMargin.fromObject(other.AccountMarginPrevious)
    } else {
      this.AccountMarginPrevious = null
    }
    if (other.AccountMarginCurrent != null) {
      this.AccountMarginCurrent = AccountMargin.fromObject(other.AccountMarginCurrent)
    } else {
      this.AccountMarginCurrent = null
    }
    if (other.TradeHistoryRecord != null) {
      this.TradeHistoryRecord = TradeHistoryRecord.fromObject(other.TradeHistoryRecord)
    } else {
      this.TradeHistoryRecord = null
    }
    if (other.TradeEvents != null) {
      this.TradeEvents = []
      for (let item of other.TradeEvents) {
        if (item != null) {
          let tempItem
          tempItem = TradeEvent.fromObject(item)
          this.TradeEvents.push(tempItem)
        } else {
          this.TradeEvents.push(null)
        }
      }
    } else {
      this.TradeEvents = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderDelete2Response}
   * @returns {!OrderDelete2Response} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderDelete2ResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderDelete2ResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderDelete2Response}
   * @param {!OrderDelete2Response} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderDelete2Response)) {
      throw new TypeError('Instance of OrderDelete2Response is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderDelete2Response}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Order: ((this.Order != null) ? this.Order : null),
      AccountMarginPrevious: ((this.AccountMarginPrevious != null) ? this.AccountMarginPrevious : null),
      AccountMarginCurrent: ((this.AccountMarginCurrent != null) ? this.AccountMarginCurrent : null),
      TradeHistoryRecord: ((this.TradeHistoryRecord != null) ? this.TradeHistoryRecord : null),
      TradeEvents: ((this.TradeEvents != null) ? Array.from(this.TradeEvents, item => ((item != null) ? item : null)) : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderDelete2Response.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderDelete2Response} other Object value
   * @returns {!OrderDelete2Response} Created struct
   */
  static fromObject (other) {
    return new OrderDelete2Response().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderDelete2Response}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderDelete2Response.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderDelete2Response}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4064
  }
}

export { OrderDelete2Response };

/**
 * Fast Binary Encoding OrderDelete2Response field model
 */
class FieldModelOrderDelete2Response extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.Order = new FieldModelOrder(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountMarginPrevious = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.Order.fbeOffset + this.Order.fbeSize), buffer, this.Order.fbeOffset + this.Order.fbeSize)
    this.AccountMarginCurrent = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.AccountMarginPrevious.fbeOffset + this.AccountMarginPrevious.fbeSize), buffer, this.AccountMarginPrevious.fbeOffset + this.AccountMarginPrevious.fbeSize)
    this.TradeHistoryRecord = new FieldModelTradeHistoryRecord(buffer, this.AccountMarginCurrent.fbeOffset + this.AccountMarginCurrent.fbeSize)
    this.TradeEvents = new fbe.FieldModelVector(new FieldModelTradeEvent(buffer, this.TradeHistoryRecord.fbeOffset + this.TradeHistoryRecord.fbeSize), buffer, this.TradeHistoryRecord.fbeOffset + this.TradeHistoryRecord.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Order.fbeSize + this.AccountMarginPrevious.fbeSize + this.AccountMarginCurrent.fbeSize + this.TradeHistoryRecord.fbeSize + this.TradeEvents.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Order.fbeExtra + this.AccountMarginPrevious.fbeExtra + this.AccountMarginCurrent.fbeExtra + this.TradeHistoryRecord.fbeExtra + this.TradeEvents.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderDelete2Response.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4064
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderDelete2Response}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderDelete2Response}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Order.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Order.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Order.fbeSize

    if ((fbeCurrentSize + this.AccountMarginPrevious.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginPrevious.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginPrevious.fbeSize

    if ((fbeCurrentSize + this.AccountMarginCurrent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginCurrent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginCurrent.fbeSize

    if ((fbeCurrentSize + this.TradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.TradeEvents.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeEvents.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEvents.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderDelete2Response}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderDelete2Response}
   * @param {!OrderDelete2Response} fbeValue Default value, defaults is new OrderDelete2Response()
   * @returns {!OrderDelete2Response} OrderDelete2Response value
   */
  get (fbeValue = new OrderDelete2Response()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderDelete2Response}
   * @param {!OrderDelete2Response} fbeValue OrderDelete2Response value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Order.fbeSize) <= fbeStructSize) {
      fbeValue.Order = this.Order.get()
    } else {
      fbeValue.Order = new Order()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Order.fbeSize

    if ((fbeCurrentSize + this.AccountMarginPrevious.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginPrevious = this.AccountMarginPrevious.get()
    } else {
      fbeValue.AccountMarginPrevious = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginPrevious.fbeSize

    if ((fbeCurrentSize + this.AccountMarginCurrent.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginCurrent = this.AccountMarginCurrent.get()
    } else {
      fbeValue.AccountMarginCurrent = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginCurrent.fbeSize

    if ((fbeCurrentSize + this.TradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.TradeHistoryRecord = this.TradeHistoryRecord.get()
    } else {
      fbeValue.TradeHistoryRecord = new TradeHistoryRecord()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.TradeEvents.fbeSize) <= fbeStructSize) {
      this.TradeEvents.get(fbeValue.TradeEvents)
    } else {
      fbeValue.TradeEvents.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEvents.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderDelete2Response}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderDelete2Response}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderDelete2Response}
   * @param {!OrderDelete2Response} fbeValue OrderDelete2Response value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderDelete2Response}
   * @param {OrderDelete2Response} fbeValue OrderDelete2Response value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Order.set(fbeValue.Order)
    this.AccountMarginPrevious.set(fbeValue.AccountMarginPrevious)
    this.AccountMarginCurrent.set(fbeValue.AccountMarginCurrent)
    this.TradeHistoryRecord.set(fbeValue.TradeHistoryRecord)
    this.TradeEvents.set(fbeValue.TradeEvents)
  }
}

export { FieldModelOrderDelete2Response };

/**
 * Fast Binary Encoding OrderDelete2Response model
 */
class OrderDelete2ResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderDelete2Response(this.buffer, 4)
  }

  /**
   * Get the OrderDelete2Response model
   * @this {!OrderDelete2ResponseModel}
   * @returns {!FieldModelOrderDelete2Response} model OrderDelete2Response model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderDelete2ResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderDelete2ResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderDelete2ResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderDelete2ResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderDelete2Response.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderDelete2ResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderDelete2ResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderDelete2ResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderDelete2ResponseModel}
   * @param {!OrderDelete2Response} value OrderDelete2Response value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderDelete2ResponseModel}
   * @param {!OrderDelete2Response} value OrderDelete2Response value, defaults is new OrderDelete2Response()
   * @return {!object} Deserialized OrderDelete2Response value and its size
   */
  deserialize (value = new OrderDelete2Response()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderDelete2Response(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderDelete2Response(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderDelete2ResponseModel}
   * @param {!number} prev Previous OrderDelete2Response model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderDelete2ResponseModel };
