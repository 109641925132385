
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {Account} from '../core/Account';
import {FieldModelAccount} from '../core/Account';
import {AccountMargin} from '../core/AccountMargin';
import {FieldModelAccountMargin} from '../core/AccountMargin';
import {TradeHistoryRecord} from '../core/TradeHistoryRecord';
import {FieldModelTradeHistoryRecord} from '../core/TradeHistoryRecord';

/**
 * AccountSocialTradingInvestorInsert2Response struct
 */
class AccountSocialTradingInvestorInsert2Response extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!Account=} account
   * @param {AccountMargin=} accountMargin
   * @param {!TradeHistoryRecord=} accountTradeHistoryRecord
   * @param {TradeHistoryRecord=} accountMarginTradeHistoryRecord
   * @constructor
   */
  constructor (parent = new Response(), account = new Account(), accountMargin = null, accountTradeHistoryRecord = new TradeHistoryRecord(), accountMarginTradeHistoryRecord = null) {
    super()
    super.copy(parent)
    this.Account = account
    this.AccountMargin = accountMargin
    this.AccountTradeHistoryRecord = accountTradeHistoryRecord
    this.AccountMarginTradeHistoryRecord = accountMarginTradeHistoryRecord
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountSocialTradingInvestorInsert2Response}
   * @param {!AccountSocialTradingInvestorInsert2Response} other Other struct
   * @returns {!AccountSocialTradingInvestorInsert2Response} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Account != null) {
      this.Account = Account.fromObject(other.Account)
    } else {
      this.Account = null
    }
    if (other.AccountMargin != null) {
      this.AccountMargin = AccountMargin.fromObject(other.AccountMargin)
    } else {
      this.AccountMargin = null
    }
    if (other.AccountTradeHistoryRecord != null) {
      this.AccountTradeHistoryRecord = TradeHistoryRecord.fromObject(other.AccountTradeHistoryRecord)
    } else {
      this.AccountTradeHistoryRecord = null
    }
    if (other.AccountMarginTradeHistoryRecord != null) {
      this.AccountMarginTradeHistoryRecord = TradeHistoryRecord.fromObject(other.AccountMarginTradeHistoryRecord)
    } else {
      this.AccountMarginTradeHistoryRecord = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountSocialTradingInvestorInsert2Response}
   * @returns {!AccountSocialTradingInvestorInsert2Response} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountSocialTradingInvestorInsert2ResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountSocialTradingInvestorInsert2ResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountSocialTradingInvestorInsert2Response}
   * @param {!AccountSocialTradingInvestorInsert2Response} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountSocialTradingInvestorInsert2Response)) {
      throw new TypeError('Instance of AccountSocialTradingInvestorInsert2Response is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountSocialTradingInvestorInsert2Response}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Account: ((this.Account != null) ? this.Account : null),
      AccountMargin: ((this.AccountMargin != null) ? this.AccountMargin : null),
      AccountTradeHistoryRecord: ((this.AccountTradeHistoryRecord != null) ? this.AccountTradeHistoryRecord : null),
      AccountMarginTradeHistoryRecord: ((this.AccountMarginTradeHistoryRecord != null) ? this.AccountMarginTradeHistoryRecord : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountSocialTradingInvestorInsert2Response.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountSocialTradingInvestorInsert2Response} other Object value
   * @returns {!AccountSocialTradingInvestorInsert2Response} Created struct
   */
  static fromObject (other) {
    return new AccountSocialTradingInvestorInsert2Response().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountSocialTradingInvestorInsert2Response}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountSocialTradingInvestorInsert2Response.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountSocialTradingInvestorInsert2Response}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4120
  }
}

export { AccountSocialTradingInvestorInsert2Response };

/**
 * Fast Binary Encoding AccountSocialTradingInvestorInsert2Response field model
 */
class FieldModelAccountSocialTradingInvestorInsert2Response extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.Account = new FieldModelAccount(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountMargin = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.Account.fbeOffset + this.Account.fbeSize), buffer, this.Account.fbeOffset + this.Account.fbeSize)
    this.AccountTradeHistoryRecord = new FieldModelTradeHistoryRecord(buffer, this.AccountMargin.fbeOffset + this.AccountMargin.fbeSize)
    this.AccountMarginTradeHistoryRecord = new fbe.FieldModelOptional(new FieldModelTradeHistoryRecord(buffer, this.AccountTradeHistoryRecord.fbeOffset + this.AccountTradeHistoryRecord.fbeSize), buffer, this.AccountTradeHistoryRecord.fbeOffset + this.AccountTradeHistoryRecord.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Account.fbeSize + this.AccountMargin.fbeSize + this.AccountTradeHistoryRecord.fbeSize + this.AccountMarginTradeHistoryRecord.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Account.fbeExtra + this.AccountMargin.fbeExtra + this.AccountTradeHistoryRecord.fbeExtra + this.AccountMarginTradeHistoryRecord.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountSocialTradingInvestorInsert2Response.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4120
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Account.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Account.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.AccountMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMargin.fbeSize

    if ((fbeCurrentSize + this.AccountTradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountTradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountTradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.AccountMarginTradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginTradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginTradeHistoryRecord.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {!AccountSocialTradingInvestorInsert2Response} fbeValue Default value, defaults is new AccountSocialTradingInvestorInsert2Response()
   * @returns {!AccountSocialTradingInvestorInsert2Response} AccountSocialTradingInvestorInsert2Response value
   */
  get (fbeValue = new AccountSocialTradingInvestorInsert2Response()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {!AccountSocialTradingInvestorInsert2Response} fbeValue AccountSocialTradingInvestorInsert2Response value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Account.fbeSize) <= fbeStructSize) {
      fbeValue.Account = this.Account.get()
    } else {
      fbeValue.Account = new Account()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.AccountMargin.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMargin = this.AccountMargin.get()
    } else {
      fbeValue.AccountMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMargin.fbeSize

    if ((fbeCurrentSize + this.AccountTradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.AccountTradeHistoryRecord = this.AccountTradeHistoryRecord.get()
    } else {
      fbeValue.AccountTradeHistoryRecord = new TradeHistoryRecord()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountTradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.AccountMarginTradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginTradeHistoryRecord = this.AccountMarginTradeHistoryRecord.get()
    } else {
      fbeValue.AccountMarginTradeHistoryRecord = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginTradeHistoryRecord.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {!AccountSocialTradingInvestorInsert2Response} fbeValue AccountSocialTradingInvestorInsert2Response value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountSocialTradingInvestorInsert2Response}
   * @param {AccountSocialTradingInvestorInsert2Response} fbeValue AccountSocialTradingInvestorInsert2Response value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Account.set(fbeValue.Account)
    this.AccountMargin.set(fbeValue.AccountMargin)
    this.AccountTradeHistoryRecord.set(fbeValue.AccountTradeHistoryRecord)
    this.AccountMarginTradeHistoryRecord.set(fbeValue.AccountMarginTradeHistoryRecord)
  }
}

export { FieldModelAccountSocialTradingInvestorInsert2Response };

/**
 * Fast Binary Encoding AccountSocialTradingInvestorInsert2Response model
 */
class AccountSocialTradingInvestorInsert2ResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountSocialTradingInvestorInsert2Response(this.buffer, 4)
  }

  /**
   * Get the AccountSocialTradingInvestorInsert2Response model
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @returns {!FieldModelAccountSocialTradingInvestorInsert2Response} model AccountSocialTradingInvestorInsert2Response model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountSocialTradingInvestorInsert2ResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountSocialTradingInvestorInsert2Response.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @param {!AccountSocialTradingInvestorInsert2Response} value AccountSocialTradingInvestorInsert2Response value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @param {!AccountSocialTradingInvestorInsert2Response} value AccountSocialTradingInvestorInsert2Response value, defaults is new AccountSocialTradingInvestorInsert2Response()
   * @return {!object} Deserialized AccountSocialTradingInvestorInsert2Response value and its size
   */
  deserialize (value = new AccountSocialTradingInvestorInsert2Response()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountSocialTradingInvestorInsert2Response(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountSocialTradingInvestorInsert2Response(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountSocialTradingInvestorInsert2ResponseModel}
   * @param {!number} prev Previous AccountSocialTradingInvestorInsert2Response model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountSocialTradingInvestorInsert2ResponseModel };
